<template>
  <div class="InvoicesPending">
    <pending-search
      v-show="!mobile.isMobile"
      class="InvoicesSearch"
      id="Testing__PendingSearchSection"
      @collapsed-state-change="expandAll = !expandAll"
      @reset-pagination="resetPagination"
      @sort-by="sort = $event"
      :page="pagination.page"
      :user-is-client="userIsClient"
    />

    <base-invoice-table
      :expand-all="expandAll"
      :sort="sort"
      :status="'pending'"
      :user-is-client="userIsClient"
    />

    <base-pagination
      @paginate="paginate"
      class="InvoicesSearch mt-40"
      :item-count="searchInfo.total"
      :page="pagination.page"
      :request-limit="pagination.requestLimit"
    />
  </div>
</template>

<script>
// Components
import BaseInvoiceTable from '../../components/base-invoice-table.vue'
import BasePagination from '../../components/base-pagination.vue'
import PendingSearch from '../../components/pending-search.vue'

/* Dev Notes:
 * All the search functionality (calling the API) is done in pending-search
 * For pagination, this component handles setting and changing pagination and
 *    sends the current page ('page') to pending-search, which is needed to
 *    perform the search API
 */

export default {
  name: 'PendingInvoices',

  components: {
    BaseInvoiceTable,
    BasePagination,
    PendingSearch,
  },

  props: {
    userIsClient: {
      type: Boolean,
      required: true,
    },
  },

  created () {
    // Check for offset in query string to set page for paid-search
    const queryString = window.location.search
    const queryParams = new URLSearchParams(queryString)

    if (queryString) {
      if (queryParams.has('page')) {
        this.$set(this.pagination, 'page', Number(queryParams.get('page')))
      }

      // When coming back from invoice-details, sort will not be passed accurately to
      // base-invoice-table if we don't grab it from the query string
      // Sort By
      if (queryParams.has('sort')) {
        if (queryParams.get('sort') !== this.sort) {
          this.sort = queryParams.get('sort')
        }
      }
    }
  },

  data () {
    return {
      expandAll: false,
      pagination: {
        page: 1,
        requestLimit: 25,
      },
      sort: 'approved_date_asc',
    }
  },

  computed: {
    mobile () {
      return this.$store.getters.mobile
    },

    searchInfo () {
      const invoices = this.$store.getters.invoices
      return {
        sum: invoices.sum,
        total: invoices.total,
      }
    },
  },

  methods: {
    paginate (page) {
      this.$set(this.pagination, 'page', page)
      window.scrollTo(0, 0)
    },

    resetPagination () {
      this.$set(this.pagination, 'page', 1)
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../../styles/invoices-search.sass'
</style>
