var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "row row--justify-between" },
        [
          _c("label", { staticClass: "InvoicesSearch__page-label" }, [
            _vm._v("Pending Invoices")
          ]),
          _c(
            "base-button",
            {
              staticClass: "bg-green fc-white",
              attrs: { "data-cy": "pending-search-new-invoice-btn" },
              on: {
                click: function($event) {
                  _vm.showNewInvoice = !_vm.showNewInvoice
                }
              }
            },
            [
              _c("i", { staticClass: "fa fa-add-circle" }),
              _vm._v("\n      New Invoice\n    ")
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row row--align-center mb-25 width-100" },
        [
          _vm.invoices.total > 0
            ? _c(
                "label",
                {
                  staticClass: "InvoicesSearch__search-results-label",
                  attrs: { id: "Testing__TotalSearchResults" }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.invoices.total) +
                      " Total\n      (" +
                      _vm._s(
                        Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD"
                        }).format(_vm.invoices.sum / 100)
                      ) +
                      ")\n    "
                  )
                ]
              )
            : _c(
                "label",
                {
                  staticClass: "InvoicesSearch__search-results-label",
                  attrs: { id: "Testing__TotalSearchResults" }
                },
                [_vm._v("\n      There are no invoices\n    ")]
              ),
          !_vm.userIsClient
            ? _c("v-select", {
                staticClass:
                  "InvoicesSearch__additional-select InvoicesSearch__additional-select--general mr-10",
                attrs: {
                  "aria-label":
                    "Looking for invoices by flags or verifications",
                  clearable: false,
                  options: _vm.options.generalFilter
                },
                on: { input: _vm.setGeneralFilter },
                model: {
                  value: _vm.inputs.generalFilter,
                  callback: function($$v) {
                    _vm.$set(_vm.inputs, "generalFilter", $$v)
                  },
                  expression: "inputs.generalFilter"
                }
              })
            : _vm._e(),
          !_vm.userIsClient
            ? _c("v-select", {
                staticClass:
                  "InvoicesSearch__additional-select InvoicesSearch__additional-select--transfer mr-10",
                attrs: {
                  "aria-label":
                    "Looking for invoices in a specific transfer time",
                  "data-cy": "invoice-transfer-time",
                  id: "Testing__TransferTimeFilter",
                  clearable: false,
                  options: _vm.options.transferTime,
                  placeholder: "Transfer Time:"
                },
                on: { input: _vm.setTransferTimeFilter },
                model: {
                  value: _vm.inputs.transferTime,
                  callback: function($$v) {
                    _vm.$set(_vm.inputs, "transferTime", $$v)
                  },
                  expression: "inputs.transferTime"
                }
              })
            : _vm._e(),
          !_vm.userIsClient
            ? _c("v-select", {
                staticClass: "InvoicesSearch__sort-by-select",
                attrs: {
                  clearable: false,
                  "data-cy": "invoice-search-sort-by",
                  options: _vm.options.sort
                },
                on: { input: _vm.setSortBy },
                model: {
                  value: _vm.inputs.sort,
                  callback: function($$v) {
                    _vm.$set(_vm.inputs, "sort", $$v)
                  },
                  expression: "inputs.sort"
                }
              })
            : _vm._e(),
          !_vm.userIsClient
            ? _c("base-toggle", {
                staticClass: "InvoicesSearch__expand-toggle",
                attrs: {
                  "active-option": _vm.expandCollapse,
                  "aria-label": "Collapse or expand items",
                  toggles: _vm.options.expandCollapse
                },
                on: { toggle: _vm.emitCollapseStateChange }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "Transition__opacity-fade" } },
        [
          _vm.showNewInvoice
            ? _c("base-invoice-create", {
                staticClass: "InvoicesSearch__InvoiceCreate mb-16",
                attrs: { "user-is-client": _vm.userIsClient },
                on: {
                  cancel: function($event) {
                    _vm.showNewInvoice = false
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }