<template>
  <div>
    <div class="row row--justify-between">
      <label class="InvoicesSearch__page-label">Pending Invoices</label>
      <base-button
        @click="showNewInvoice = !showNewInvoice"
        class="bg-green fc-white"
        data-cy="pending-search-new-invoice-btn"
      >
        <i class="fa fa-add-circle" />
        New Invoice
      </base-button>
    </div>
    <!-- END SEARCH SECTION -->

    <!-- Total invoices and value + Sort && Collapse toggles -->
    <div class="row row--align-center mb-25 width-100">
      <label
        v-if="invoices.total > 0"
        class="InvoicesSearch__search-results-label"
        id="Testing__TotalSearchResults"
      >
        {{ invoices.total }} Total
        ({{
          Intl
            .NumberFormat('en-US', { style: 'currency', currency: 'USD' })
            .format(invoices.sum / 100)
        }})
      </label>
      <label
        v-else
        class="InvoicesSearch__search-results-label"
        id="Testing__TotalSearchResults"
      >
        There are no invoices
      </label>

      <v-select
        v-if="!userIsClient"
        v-model="inputs.generalFilter"
        @input="setGeneralFilter"
        aria-label="Looking for invoices by flags or verifications"
        class="InvoicesSearch__additional-select InvoicesSearch__additional-select--general mr-10"
        :clearable="false"
        :options="options.generalFilter"
      />

      <v-select
        v-if="!userIsClient"
        v-model="inputs.transferTime"
        @input="setTransferTimeFilter"
        aria-label="Looking for invoices in a specific transfer time"
        class="InvoicesSearch__additional-select InvoicesSearch__additional-select--transfer mr-10"
        data-cy="invoice-transfer-time"
        id="Testing__TransferTimeFilter"
        :clearable="false"
        :options="options.transferTime"
        :placeholder="'Transfer Time:'"
      />

      <v-select
        v-if="!userIsClient"
        v-model="inputs.sort"
        @input="setSortBy"
        class="InvoicesSearch__sort-by-select"
        :clearable="false"
        data-cy="invoice-search-sort-by"
        :options="options.sort"
      />

      <base-toggle
        v-if="!userIsClient"
        @toggle="emitCollapseStateChange"
        :active-option="expandCollapse"
        aria-label="Collapse or expand items"
        class="InvoicesSearch__expand-toggle"
        :toggles="options.expandCollapse"
      />
    </div>

    <transition name="Transition__opacity-fade">
      <base-invoice-create
        v-if="showNewInvoice"
        @cancel="showNewInvoice = false"
        class="InvoicesSearch__InvoiceCreate mb-16"
        :user-is-client="userIsClient"
      />
    </transition>
  </div>
</template>

<script>
// Helpers
import {
  Invoice
} from '../utils/api'
import {
  firstAchTime,
  secondAchTime,
  stringifyObjectToQueryString,
  transferBucketTime,
} from '../utils/helpers'
// Components
import BaseButton from './base-button.vue'
// import BaseClientSearch from './base-client-search.vue'
import BaseInvoiceCreate from './base-invoice-create.vue'
import BaseToggle from './base-toggle.vue'

export default {
  name: 'PendingSearch',

  components: {
    BaseButton,
    // BaseClientSearch,
    BaseInvoiceCreate,
    BaseToggle,
  },

  props: {
    page: { // Used for pagination
      type: Number,
      required: true,
    },
    userIsClient: {
      type: Boolean,
      required: true,
    },
  },

  async created () {
    this.$store.commit('CLEAR_INVOICES')

    // Set transfer time (must be at top so it can be overrode by query params, if so)
    const whichBucket = transferBucketTime()
    this.$set(this.inputs, 'transferTime', whichBucket)
    this.setTransferTimeFilter(whichBucket, true)

    // Query string? Compare variables with component data; if different, perform search
    const queryString = window.location.search
    const queryParams = new URLSearchParams(queryString)

    // Check for search variables, update component data
    if (queryString) {
      // TODO: Paul asked to disable/comment out the client field
      // Client ID
      // if (queryParams.has('client_id')) {
      //   if (queryParams.get('client_id') !== this.apiValues.client_id) {
      //     this.$set(this.apiValues, 'client_id', Number(queryParams.get('client_id')))
      //   }
      // }

      // Type
      if (queryParams.has('type')) {
        if (queryParams.get('type').toLowerCase() !== this.inputs.flagged) {
          this.setGeneralFilter(queryParams.get('type'), true)
        }
      }

      // Sort By
      if (queryParams.has('sort')) {
        if (queryParams.get('sort') !== this.inputs.sort) {
          this.$set(this.inputs, 'sort', queryParams.get('sort'))
          this.$emit('sort-by', queryParams.get('sort')) // Required for invoice categorization
        }
      }
    }

    // If userIsClient, set client_id
    if (this.userIsClient) {
      const client = JSON.parse(localStorage.getItem('user')).client
      this.$set(this.apiValues, 'client_id', client.id)
    }

    this.search()
  },

  mounted () {
    // it needs to refresh results every 5 minutes for clients only
    if (this.userIsClient) {
      this.startSearchInterval()
      this.$root.$on('restart-search', () => {
        this.restartSearchInterval()
      })
    }
  },

  beforeDestroy() {
    // it should stop searching when you leave the page
    if (this.searchInterval) clearInterval(this.searchInterval)
    this.$root.$off('restart-search')
  },

  data () {
    return {
      apiValues: {
        client_id: null,
        flagged: null,
        requires_verification: null,
        transfer_time: null,
        transfer_type: null,
      },
      expandCollapse: '<i class="fa fa-4-bars fa-15"></i>',
      inputs: {
        flagged: 'Both',
        generalFilter: 'All Invoices',
        transferTime: '',
        verification: 'Both',
        sort: 'time',
      },
      options: {
        expandCollapse: [
          '<i class="fa fa-4-bars fa-15"></i>',
          '<i class="fa fa-hamburger fa-15"></i>',
        ],
        flagged: ['Both', 'No', 'Yes'],
        generalFilter: ['All Invoices', 'Verifications', 'Flagged', 'Clean Invoices'],
        sort: ['time', 'client', 'debtor'],
        transferTime: ['Wire', firstAchTime, secondAchTime],
        verification: ['Both', 'No', 'Yes'],
      },
      resultsPerPage: 25,
      searchInterval: null,
      showFilters: false,
      showNewInvoice: false,
    }
  },

  computed: {
    invoices () {
      return this.$store.getters.invoices
    },

    mobile () {
      return this.$store.getters.mobile
    },
  },

  watch: {
    page () {
      this.search()
    }
  },

  methods: {
    clickOnSearch () {
      this.$emit('reset-pagination')
      this.search()
    },

    configureSearchParams () {
      // Default "time" sorts
      let sort_by = 'created_at'
      let sort_direction = 'ASC'

      if (this.inputs.sort === 'client') {
        sort_by = 'client_pending_total_amount'
        sort_direction = 'DESC'
      } else if (this.inputs.sort === 'debtor') {
        sort_by = 'debtor_pending_total_amount'
        sort_direction = 'DESC'
      }

      const params = {
        forceAllInvoiceIds: true, // Returns the IDs of the each invoice in the results
        is_buyout: false, // Default
        offset: (this.page - 1) * this.resultsPerPage,
        sort_by,
        sort_direction,
        status: 'pending',
        transfer_time: this.userIsClient ? null : this.apiValues.transfer_time,
        transfer_type: this.userIsClient ? null : this.apiValues.transfer_type
      }

      if (this.inputs.generalFilter === 'verifications') {
        params.requires_verification = true
      }
      else if (this.inputs.generalFilter === 'flagged') {
        params.flagged = true
      }
      else if (this.inputs.generalFilter === 'clean invoices') {
        params.clean_invoices = true
      }

      return params
    },

    emitCollapseStateChange () {
      if (this.expandCollapse === '<i class="fa fa-4-bars fa-15"></i>') {
        this.expandCollapse = '<i class="fa fa-hamburger fa-15"></i>'
      } else {
        this.expandCollapse = '<i class="fa fa-4-bars fa-15"></i>'
      }
      this.$emit('collapsed-state-change')
    },

    getAlgoliaSortByValue (sort) {
      // Clients should see invoices by newest first (only applies to 'time')
      if (sort === 'time') {
        return this.userIsClient
          ? 'created_at_desc'
          : 'created_at_asc'
      }
      if (sort === 'client') return 'client_pending_total_desc'
      if (sort === 'debtor') return 'debtor_pending_total_desc'
    },

    restartSearchInterval () {
      if (this.searchInterval) clearInterval(this.searchInterval)
      this.startSearchInterval()
    },

    async search () {
      const queryString = stringifyObjectToQueryString({
        client_id: this.apiValues.client_id,
        page: this.page,
        transfer_time: this.userIsClient ? null : this.apiValues.transfer_time,
        transfer_type: this.userIsClient ? null : this.apiValues.transfer_type,
        type: this.inputs.generalFilter,
        sort: this.inputs.sort,
      })
      // API Request (https://fs-bobtail.github.io/bobtail/#api-Invoice-SearchInvoices)
      // searchParams are null if the user hasn't searched for anything
      // i.e. this covers the case a user just paginates the invoices from onload
      try {
        this.progressStart()

        const results = (await Invoice.queryList(this.configureSearchParams(), this.resultsPerPage)).data

        // Convert URL to a URL representing data of request
        window.history.replaceState({}, '', `?${queryString}`)
        // Store search in localStorage for invoice-details
        window.localStorage.setItem('search', JSON.stringify({
          ids: results.all_invoice_ids,
          params: this.configureSearchParams(),
          url: `/invoices/pending?${queryString}`,
        }))
        this.$store.commit('STORE_INVOICES', results)
        this.progressFinish()
      }
      catch (error) {
        this.captureSentryEvent(
          'Pending Search "Search" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
            queryString,
          }
        )
        this.CError(error)
        this.requestFailure({
          message: 'There was an issue searching for potential matching invoices',
          title: 'Request error'
        })
      }
    },

    setClientID (filter) {
      if (!filter) {
        this.apiValues.client_id = null
        return
      }
      this.apiValues.client_id = filter.id
    },

    async setGeneralFilter (filter, skipSearch = false) {
      // set them initally null
      this.$set(this.apiValues, 'requires_verification', null)
      this.$set(this.apiValues, 'flagged', null)
      this.$set(this.inputs, 'generalFilter', filter.toLowerCase())

      if (filter.toLowerCase() === 'verifications') {
        this.$set(this.apiValues, 'requires_verification', true)
      }
      else if (filter.toLowerCase() === 'flagged') {
        this.$set(this.apiValues, 'flagged', true)
      }
      else if (filter.toLowerCase() === 'clean invoices') {
        this.$set(this.apiValues, 'requires_verification', false)
        this.$set(this.apiValues, 'flagged', false)
      }

      // In created, we don't want to cause a search everytime we set a search parameter
      if (!skipSearch) {
        // Search is performed with this.page changes, but if it won't change, trigger search
        // This prevents double search being triggered when performing a new search when NOT
        // on page one of the current results
        if (this.page !== 1) this.$emit('reset-pagination')
        else this.search()
      }
    },

    setTransferTimeFilter (filter, skipSearch = false) {
      // on mobile don't filter by transfer time or type
      if (this.mobile.isMobile) return
      // Set local data
      if (filter.toLowerCase() === 'wire') {
        this.$set(this.apiValues, 'transfer_type', 'wire')
        this.$set(this.apiValues, 'transfer_time', null)
      } else {
        // If it's not 'wire', remove the field
        this.$set(this.apiValues, 'transfer_type', 'ach')
        this.$set(this.apiValues, 'transfer_time', filter.toLowerCase())
      }

      // In created, we don't want to cause a search everytime we set a search parameter
      if (!skipSearch) {
        // Search is performed with this.page changes, but if it won't change, trigger search
        // This prevents double search being triggered when performing a new search when NOT
        // on page one of the current results
        if (this.page !== 1) this.$emit('reset-pagination')
        else this.search()
      }
    },

    setSortBy() {
      // We need to clear all the invoices in Vuex before initiating this change, because
      // the computed properties in base-invoice-table will try to sort the CURRENT invoices
      // before we can perform a new search with the new, proper sort
      this.$store.commit('CLEAR_INVOICES')
      // API request with updated sort value
      this.$emit('sort-by', this.inputs.sort)
      // Search is performed with this.page changes, but if it won't change, trigger search
      // This prevents double search being triggered when performing a new search when NOT
      // on page one of the current results
      if (this.page !== 1) this.$emit('reset-pagination')
      else this.search()
    },

    startSearchInterval () {
      this.searchInterval = setInterval(() => {
        this.search()
      }, 5 * 60000)
    },
  },
}
</script>

<style lang="sass">
@import '../styles/invoices-search.sass'
</style>
